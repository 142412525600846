import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Receipt } from '../components/Receipt'
import { useSelector, RootStateOrAny } from 'react-redux';
import { Header } from './../components/Header';
import { Footer } from './../components/Footer';
import { PageWithBackgroundImage } from "../components/pageWithBackgroundImage";


interface Props {
  state?: {}
}

export const Success = (props: any) => {

  let ssrState = props?.staticContext?.location || {};

  // isomorphic state 
  const [overallState, setOverallState] = useState(ssrState);

  // state from client side 
  const clientState: any = useSelector((state: RootStateOrAny) => {
    return state
  });

  const { checkout: { paymentResponse = {} } = {}, appData: { content = {}, domain = '' } = {} } = overallState;


  useEffect(() => {
    if (clientState && clientState.checkout && Object.keys(clientState.checkout).length) {
      setOverallState(clientState);
    }
  }, [clientState])
  return (<PageWithBackgroundImage content={content} domain={domain}>
    <Header content={content} domain={domain} />
    <Helmet>
      <title>Success Page</title>
    </Helmet>
    <Receipt content={content} paymentResponse={paymentResponse} />
    <Footer content={content} />
  </PageWithBackgroundImage>)
}

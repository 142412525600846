import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { useLocation } from "react-router-dom";
import clsx from 'clsx';
import { mobPageWithBackgroundImgStyle } from "../tenant/style";


const MobilePageWithBackgroundImage = withStyles(
  (mobPageWithBackgroundImgStyle),
  { name: "MobilePageWithBackgroundImage" }
)((props: any) => {
  const { classes } = props;
  const path = useLocation().pathname;
  const location = path.split("/")[1];
  const className = clsx(classes[location]) 
    return (
      <div className={classes.root}>
      {props.children}
      </div>
      )
 
});

export { MobilePageWithBackgroundImage };

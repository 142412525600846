import 'isomorphic-fetch';
import {Query} from '../routes'

export const fetchPost = (url:string, data?:any, form?:boolean,slice?:string, state?:string, query?:Query) =>{

  const {PRE_FLIGHT_API_TOKEN, port, gateWayUrl='http://client', X_ORIGIN_VERIFY=''} = process.env;


  
  const options:any = {
    method: 'POST',
   
    body: (form)? data: JSON.stringify(data) // body data type must match "Content-Type" header
  };

  if(!form){
    options.headers = {
      'Content-Type': 'application/json',
      'X-Origin-Verify': X_ORIGIN_VERIFY
    };
  } else {
    options['redirect'] = 'follow';
  }



  return fetch(`${gateWayUrl}/${url}`, options)
  .then((res:any) => {
    let ret 

    try {
      ret = (form)? res: res.json();
    } catch (error) {
      console.log('fetch json', error)
      ret = error;
    }
    return ret
  })
  .then((data:any) => {
    data['slice']= slice;
    data['state']= state;

    return data;
  })
  .catch((error:any) =>{
    console.log('fetchPost:::', error)
  });
}

import React, { useEffect, useState } from 'react'
import { Box, Container, Grid, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, RootStateOrAny } from 'react-redux'
import { ProductsGrid } from '../components/ProductsGrid';
import { PageWithBackgroundImage } from "../components/pageWithBackgroundImage";

import { Header } from './../components/Header';
import { Footer } from './../components/Footer';

import { home } from './../tenant/style'

declare global {
  var window: Window & typeof globalThis;
}

export interface Product {
  name?: string,
  id?: number,
  title?: string,
  description?: string,
  price?: any

};

const GridChild = (props: any) => {
  
  const { products, spacing } = props
  return (
    <Grid container spacing={spacing} >
      {products.map((product: Product, i: number) => {
        return (
          <ProductsGrid product={product} />
        )
      })}
    </Grid>
  )

}


const HomeMain = (props: any) => {
  const useStyles = makeStyles(home);
  const classes = useStyles();

  
  const { products, content } = props
  return (

    <Container  >

      <Typography variant="h5" align="center" className={classes.h5}>
        {content?.PURCHASE_WIFI_BEFORE_YOUR_FLIGHT}
      </Typography>
      <Typography variant="h6" align="center" className={classes.h6}>
        {content?.PICK_AVAILABLE_PLANS}
      </Typography>

      <Box className={classes.boxMargin} justifyContent='center' >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classes.rootGridTag}
        >
          <div className={classes.boxTag} >
            <div className={classes.smDownTag}>
              <GridChild products={products} spacing={2} />
            </div>
            <div className={classes.mdUpTag}>
              <GridChild products={products} spacing={7} />
            </div>
          </div>

        </Grid>
      </Box>

      <div className={classes.divHeightSpace}>
        &nbsp;
      </div>
    </Container>

  )

}

export const Home = (props: any) => {

  // state returned server-side
  const ssrState = props?.staticContext?.location || {};
  // isomorphic state 
  const [overallState, setOverallState] = useState(ssrState);

  // state from client side 
  const clientState: any = useSelector((state: RootStateOrAny) => {
    return state

  });

  const { productsPrices: { products: { products = [] } = {} } = {}, appData: { content = {}, domain=''} = {} } = overallState;


  useEffect(() => {
    if (clientState && Object.keys(clientState.productsPrices).length) {
      setOverallState(clientState);
    }
  }, [clientState])


  return (

    <PageWithBackgroundImage content={content} domain={domain} >
      <Header content={content} domain={domain} />
        <HomeMain products={products} content={content}  />
      <Footer content={content}  />
    </PageWithBackgroundImage>


  )

}

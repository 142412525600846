import React, { useState } from "react";
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { useSelector } from "react-redux";
import { Container } from "@material-ui/core";
import TableContainer from '@material-ui/core/TableContainer';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { orderSummary } from './../tenant/style'


import {
  makeStyles,
  Grid,
} from "@material-ui/core";


const useStyles = makeStyles(orderSummary);



export const OrderSummary = (props: any) => {
  const classes = useStyles();
  const { selectedProduct = {}, form = {}, content = {} } = props;
  const locale = useSelector((state: any) => state?.appData?.locale)
  const currency = useSelector((state: any) => state?.appData?.currency);
  const [open, setOpen] = useState(false);

  const selectObj = selectedProduct?.price?.currency && selectedProduct?.price?.allCurrencies && selectedProduct?.price?.allCurrencies.length > 0 && selectedProduct?.price?.allCurrencies.filter((o: any) => Object.keys(o).some(k => { return (currency?.toString() === o.currencyCode + "") })) || [];
  const totalCostLabel = Intl.NumberFormat(props?.content?.LOCALE, { minimumFractionDigits: 2 }).format(Number(selectObj && selectObj[0]?.totalValue));
  const valueLabel = Intl.NumberFormat(props?.content?.LOCALE, { minimumFractionDigits: 2 }).format(Number(selectObj && selectObj[0]?.value));
  const rateValueLabel = Intl.NumberFormat(props?.content?.LOCALE, { minimumFractionDigits: 2 }).format(Number(selectObj && selectObj[0]?.rateValue));
  const symbol = selectObj && selectObj[0]?.symbol;

  function FormTable(details: any) {

    return (
      <React.Fragment>
        <TableContainer >
          <Table aria-label="collapsible table">

            <TableBody>

              <TableRow hover role="checkbox">
                <TableCell className={classes.primaryHeading} align="left">{selectedProduct?.name}</TableCell>
                <TableCell className={classes.primaryHeading} align="right">{symbol + ' ' + valueLabel}</TableCell>
              </TableRow>

              <TableRow hover role="checkbox">
                <TableCell className={classes.primaryHeading} align="left">{content?.CHECKOUT_GST_LABEL + '(' + (selectObj && selectObj[0]?.rate) + '%)'}</TableCell>
                <TableCell className={classes.primaryHeading} align="right">{symbol + ' ' + Number(rateValueLabel).toFixed(2)}</TableCell>
              </TableRow>

              <TableRow hover role="checkbox">
                <TableCell className={classes.primaryHeadingLastChild} align="left">{content?.CHECKOUT_TOTALCOST_LABEL}</TableCell>
                <TableCell className={classes.primaryHeadingLastChild} align="right">{symbol + ' ' + totalCostLabel}</TableCell>
              </TableRow>

            </TableBody>

          </Table>
        </TableContainer>
      </React.Fragment>
    );
  }

  return (
    <div className={classes.root}>

      <Container>
        <Box className={classes.boxTag}>
          <Accordion className={classes.accordionTag}>
            <AccordionSummary
              onClick={() => setOpen(!open)}
            >

              <table className={classes.tableWidth}>
                <tr>
                  <td className={classes.td1}>
                    <table  >
                      <tr>
                        <td className={classes.ptitle}>{content?.CHECKOUT_PACK_SELECTED_LABEL}</td>
                      </tr>
                      <tr>
                        <td className={classes.pHeading}>{selectedProduct?.name}</td>
                      </tr>
                    </table>
                  </td>

                  <td className={classes.vl} />


                  <td className={classes.td2}>
                    <table >
                      <tr>
                        <td className={classes.ptitle}>{content?.CHECKOUT_TOTALCOST_LABEL + '  '}&nbsp;&nbsp;&nbsp;</td>
                      </tr>
                      <tr>
                        <td className={classes.pHeading}>{symbol + ' ' + totalCostLabel}</td>
                      </tr>
                    </table>
                  </td>

                  <td className={classes.vl} />


                  <td className={classes.td3}>
                    <table >
                      <tr>
                        <td className={classes.pDetails}>{content?.CHECKOUT_DETAILS_LABEL}</td>
                      </tr>
                      <tr>
                        <td className={classes.pHeadingLast}>{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</td>
                      </tr>
                    </table>
                  </td>
                </tr>

                <tr className={classes.tr2}>
                  <td>&nbsp;</td>
                </tr>
              </table>


            </AccordionSummary>
            <AccordionDetails className={classes.details} >
              <Grid container item xs={12} spacing={3}>
                <FormTable details={selectedProduct} />
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Typography variant="h5" align="center" >
          &nbsp;
        </Typography>
      </Container>
    </div>


  );
};

import { withStyles, makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useLocation } from "react-router-dom";
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { pageWithBackgroundImageStyle } from './../tenant/style'

export const useStyles = makeStyles(pageWithBackgroundImageStyle);

const PageWithBackgroundImage = (props:any) => {

  const classes = useStyles(props);
  const path = useLocation().pathname;
  const location = path.split("/")[1];
  const className = clsx(classes[location]);

  if (location) {
    return (
      <Box className={classes.boxTag}>
        <div className={className} >
          {props.children}
        </div>
      </Box>
    )
  } else {
    return (
      <Box className={classes.boxTag}>
        <div className={classes.root} >
          {props.children}
        </div>
      </Box>

    )
  }
};



export { PageWithBackgroundImage };

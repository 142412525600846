import React, { useEffect, useState } from 'react'
import { OrderSummary } from '../components/OrderSummary';
import { CheckoutEmail } from "../components/CheckoutEmail";
import { useSelector, RootStateOrAny } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
} from "@material-ui/core";
import { Header } from './../components/Header';
import { Footer } from './../components/Footer';
import { PageWithBackgroundImage } from "../components/pageWithBackgroundImage";
import { checkout } from './../tenant/style';
 
const useStyles = makeStyles(checkout);

interface Props {
  state?: {}
}

export const Checkout = (props: any) => {

  const classes = useStyles();

  let ssrState = props?.staticContext?.location || {};

  // isomorphic state 
  const [overallState, setOverallState] = useState(ssrState);

  // state from client side 
  const clientState: any = useSelector((state: RootStateOrAny) => {
    return state

  });

  const { checkout: { selectedProduct = {}, form = {} } = {}, appData: { content = {}, domain = '' } = {} } = overallState;
  
  useEffect(() => {
    try {
        if (clientState && clientState.checkout && Object.keys(clientState.checkout).length) {
          setOverallState(clientState);
        }
    } catch (error) {
      console.log('useEffect', error)
    }

  }, [clientState])

  return (
    <PageWithBackgroundImage content={content} domain={domain} >
      <Header content={content} domain={domain} />
        <OrderSummary  selectedProduct={selectedProduct} content={content} form={form} />
        <Grid item xs={12} md={12}>
          <CheckoutEmail form={form} selectedProduct={selectedProduct} content={content} />
        </Grid>
        <Typography className={classes.spaceHeight} variant="h5" align="center" >
            &nbsp;
        </Typography>
      <Footer content={content} />
    </PageWithBackgroundImage>
         
  )
}

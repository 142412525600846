import { createMuiTheme } from "@material-ui/core";
import { airlineThemePalette } from "../tenant/style";


export const createAirlineTheme= (theme:any,  brandingState:any)=> {
  return {
    overrides: {
      MuiPaper:{
        root: {
          color:"none"
        }
      },
      MuiAppBar: {
        root: {
          boxShadow: "none"
        },
        colorPrimary: {
          backgroundColor: "transparent"
        }
      },
      MuiButton: {
        root: {
          fontSize: "1rem",
          borderRadius: "0px"
        },
        label: {
          color: "#46143C"
        },
        sizeLarge: {
          fontSize: "1.3rem"
        },
        contained: {
          "&$disabled": {
            backgroundColor: "#C7D1D6"
          }
        }
      },
      MuiButtonBase:{
        root: {
          backgroundColor:"transparent",
          color:"none"
        }
      },
      MuiDialog: {
        paper: {
          margin: theme.spacing(2),
          padding: theme.spacing(1)
        },
        paperFullWidth: {
          width: `calc(100% - ${theme.spacing(1)}px)`
        }
      },
      MuiDialogActions: {
        root: {
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(2),
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          [theme.breakpoints.up("md")]: {
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(5),
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(5)
          },
          [theme.breakpoints.up("lg")]: {
            paddingLeft: theme.spacing(10),
            paddingRight: theme.spacing(10)
          }
        }
      },
      MuiListItemText: {
        primary: {
          fontFamily: brandingState.font.fontFamilyValues["font-family-bold"]
        },
        secondary: {
          opacity: 0.5
        }
      },
      MuiCssBaseline: {
        "@global": {
          body: {
            backgroundColor: "transparent"
          }
        }
      }
    },
    props: {
    }
  }
}

const rtlLanguages = ["ar", "fa", "he", "iw", "ur", "yi", "ji"];

const isLanguageRTL = (locale:string) => rtlLanguages.includes(locale);

export const createAirTheme = (brandingState:any, locale:string='en') => {
  const isRTL:'ltr' | 'rtl' = (isLanguageRTL(locale))? 'ltr' : 'rtl';

//  const headerBackgroundColor = brandingState.colors["header-background-color"] || "rgba(0, 0, 0, 0)";

  let theme = createMuiTheme({
    direction: isRTL,
    typography: {
      fontFamily: [
        brandingState.font.fontFamilyValues["font-family"],
        brandingState.font.fontFamilyValues["font-family-light"],
        brandingState.font.fontFamilyValues["font-family-regular"],
        brandingState.font.fontFamilyValues["font-family-medium"],
        brandingState.font.fontFamilyValues["font-family-semibold"],
        brandingState.font.fontFamilyValues["font-family-bold"],
        brandingState.font.fontFamilyValues["font-family-sans-serif"]
      ].join(","),
      h1: {
        fontFamily: brandingState.font.fontFamilyValues["font-family-bold"]
      },
      h2: {
        fontFamily: brandingState.font.fontFamilyValues["font-family-bold"]
      },
      h3: {
        fontFamily: brandingState.font.fontFamilyValues["font-family-bold"]
      },
      h4: {
        fontFamily: brandingState.font.fontFamilyValues["font-family-bold"]
      },
      h5: {
        fontFamily: brandingState.font.fontFamilyValues["font-family-bold"]
      },
      h6: {
        fontFamily: brandingState.font.fontFamilyValues["font-family-bold"]
      }
    },
    palette: {
      background: (brandingState.colors["header-background-color"] || "rgba(0, 0, 0, 0)")
    }
  
  });
 
  // Run createMuiTheme once to get defaults and generated values for colors, breakpoints etc.
  // Then run it again for overrides and props, this time with said generated values available in the
  // theme variable:

  //const airlineThemePalette = createAirlineThemePalette(theme, brandingState);
  if (airlineThemePalette) {
    theme = createMuiTheme({
      ...theme,
      ...airlineThemePalette
    });
  } 

  const airlineTheme = createAirlineTheme(theme, brandingState);
  if (airlineTheme) {

    theme = createMuiTheme({
      ...theme,
      ...airlineTheme
    });
  }

  return { theme, isRTL };
};

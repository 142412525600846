import {combineReducers} from 'redux';

import { appConstants, productsConstants, checkoutConstants } from '../constants';





const appDataDefualt = {
  currentPage: null, 
  currency:'INR',
  locale:'en_US',
  domain: '',
  features:{
    currentPortalStateEndpoint: false,
    forceLegacyDiscounts: true,
    intrusiveLegalChange: true,
    intrusivePasswordChange: true,
    bootCaptcha: true,
    showablePasswords: true,
    autoCheckoutIfFree: true,
    autoReconnectGuest: true,
    ccYearCount: 15,
    browserNotifications: true,
    checkout: { needsToAcceptToS: true },
    user: { needsToAcceptLegals: false, personalInfoAgreement: false, userAgreeToPrivacyPolicy: true },
    connection: { receiptForGuest: true, receiptForRegular: false, pauseConnectionEnabled: true },
    UI: {
      hasVouchers: true,
      hasRoaming: true,
      hasIpass: true,
      hasBoingo: true,
      hasReconnect: true,
      showUsageInfo: true,
      showFAQLink: true,
      showContactUsLink: true,
      showWriteUs: true,
      showCallUs: false,
      showVersionInFooter: true,
      gdprEnabled: false,
      showLangugageSelectorIcon: true,
      showLanguageSelectorText: true,
      showLanguageSelectorWithCountryFlags: false,
      monthlyHeroImage: false
    }
    },
  locales:[{id:'en_US', title:'English'}, {id:'fr_FR', title:'French'}]
}

export  const appData = (state = appDataDefualt, action:any) => {
  switch (action.type) {
    case appConstants.SET_STATE:
      return Object.assign({}, state, {
        domain: action.payload?.appData?.domain,
        currentPage: action.payload?.appData?.currentPage,
        locale: action.payload?.appData?.locale,
        locales: action.payload?.appData?.locales,
        content: action.payload?.appData?.content,
        features: action.payload?.appData?.features,
        currency: action.payload?.appData?.currency
      });
    case appConstants.CURRENT_PAGE:
      return Object.assign({}, state, {
        currentPage: action.id
      });
    case appConstants.LOCALE:
    case appConstants.SET_LOCALE:

      return Object.assign({}, state, {
        locale: action.payload
      });
    case appConstants.LOCALES:
      return Object.assign({}, state, {
        locales: action.payload
      });
    case appConstants.CONTENT:
      return Object.assign({}, state, {
        content: action.payload
      });
    case appConstants.FEATURES:
      return Object.assign({}, state, {
        features: action.payload
      });
    case appConstants.PURGE_DATA:
      // Reset the app state to begining
      return {
        currentPage: null
      };

    default:
      return state
  }
}



export  const productsPrices = (state = {products:{}}, action:any) => {
    switch (action.type) {
      case appConstants.SET_STATE:
        return Object.assign({}, state, {
          products: action.payload?.productsPrices?.products
        });
      case productsConstants.PRODUCTS_PRICES:
        return Object.assign({}, state, {
            products: action.data
        });
      case appConstants.PURGE_DATA:
        // Reset the app state to begining
        return {
          products:{}
        };
  
      default:
        return state
    }
  }

  export  const checkout = (state = {selectedProduct:{}, paymentResponse:{}}, action:any) => {
    switch (action.type) {
      case appConstants.SET_STATE:
        return Object.assign({}, state, {
          selectedProduct: action.payload?.checkout?.selectedProduct,
          paymentResponse: action.payload?.checkout?.paymentResponse,
          form: action.payload?.checkout?.form
        });
      case checkoutConstants.SELECTED_PRODUCT:
        return Object.assign({}, state, {
          selectedProduct: action.data
        });
      case checkoutConstants.PAYMENT_RESPONSE:
        return Object.assign({}, state, {
          paymentResponse: action.data
        });
      case checkoutConstants.FORM:
        return Object.assign({}, state, {
          form: action.data
        });
      case appConstants.PURGE_DATA:
        // Reset the app state to begining
        return {
          selectedProduct:{}
        };
  
      default:
        return state
    }
  }
  
  



  export const rootReducer = combineReducers({
    productsPrices,
    appData,
    checkout
})
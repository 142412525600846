import { ButtonBase, Typography, Grid } from "@material-ui/core";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import React, { ReactElement, FC } from "react";
import { dropDownElement } from "../tenant/style";

export interface DefaultTheme {
  palette?: any,
  headerBackgroundColor?: string,
  spacing?: any
}



const styles = dropDownElement;

const DropDownElement = (props: any) => {
  const { items = [], selectedItem, onChange, icon, showSelectedText, classes, screenType, content } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  function handleClick(event: any) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose(event: any) {
    setAnchorEl(null);
  }
  return (
    <div className={classes.divTag}>



      {screenType && screenType == "mobile" ? (
        <div>
          <Grid
            container
            direction="column"
            alignItems="center"
            className={classes.gridTag}
          >
            <ButtonBase style={{ height: "40px" }} className={classes.buttonBase} >
              {icon}
              <Typography variant="body1" >
                <a className={classes.buttonText} href="/help?locale=en_US">{content?.HEADER_HELP}</a>
              </Typography>
            </ButtonBase>         
          </Grid>
        </div>
      ) : (
        <div>
          <ButtonBase className={classes.buttonBase} >
            {icon}
            <Typography variant="body1" className={classes.buttonText}>
              <a className={classes.buttonText} href="/help?locale=en_US">{content?.HEADER_HELP}</a>
            </Typography>
          </ButtonBase>         
        </div>
      )}

    </div>
  );
      }

const DropDown = withStyles(styles)(DropDownElement);

export default DropDown;

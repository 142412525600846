export const appConstants = {
  SET_STATE: 'APP_SET_STATE',
  CURRENT_PAGE: 'APP_CURRENT_PAGE',
  CLOSE: 'APP_CLOSE',
  LOCALE: 'APP_LOCALE',
  LOCALES: 'APP_LOCALES',
  SET_LOCALE:'APP_SET_LOCALE',
  CONTENT: 'APP_CONTENT',
  FEATURES: 'APP_FEATURES',
  PURGE_DATA: 'PURGE_DATA'
};

export const productsConstants = {
  PRODUCTS_PRICES: 'PRODUCTS_PRODUCTS_PRICES',
  PURGE_DATA: 'PURGE_DATA'
};

export const checkoutConstants = { 
  SELECTED_PRODUCT: 'CHECKOUT_SELECTED_PRODUCT',
  PAYMENT_RESPONSE: 'CHECKOUT_PAYMENT_RESPONSE',
  FORM:'CHECKOUT_FORM',
  PURGE_DATA: 'PURGE_DATA'
};


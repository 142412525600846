import React from 'react'
//import styled from 'styled-components'
//import { useSelector } from "react-redux";
import {
  Grid,
  Box,
  makeStyles,
  Paper,
  Container,
  Typography
} from '@material-ui/core';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import moment from 'moment';
import { receipt } from './../tenant/style';


const useStyles = makeStyles(receipt);

function ReplaceTextFunction(inputStr: any, replaceStr: any, outputStr: any) {

  return inputStr && inputStr.toString().replace(replaceStr, outputStr);

}

export const Receipt = (props: any) => {
  
  const { paymentResponse = {}, content = {} } = props;
 
  const classes = useStyles();

  const objArrlst = content?.SUCCESSPAGE_DESC_LIST || [];

  const selectObj = paymentResponse?.product?.price?.currency && paymentResponse?.product?.price?.allCurrencies && paymentResponse?.product?.price?.allCurrencies.length > 0 && paymentResponse?.product?.price?.allCurrencies.filter((o: any) => Object.keys(o).some(k => { return (paymentResponse?.customParam_currency?.toString() === o?.currencyCode?.toString()) })) || [];

  const prodDescription = paymentResponse?.product?.name + ' - ' + paymentResponse?.product?.description;
  let utcDate = moment(paymentResponse?.utcDatetime, 'YYYY-MM-DDTHH:mm:ss').format("MMM DD, YYYY HH:mm") + ' UTC';
  let expDate = moment(paymentResponse?.utcDatetime, 'YYYY-MM-DDTHH:mm:ss').add(3, 'months').format("MMM DD, YYYY HH:mm") + ' UTC'; 

  const SuccessVoid = () => {

    const success = (paymentResponse.status == 'APPROVED');

    if (success) {
      return (

        <div>
          <Container >
            <Grid container className={classes.root} >
              <Grid item>
                <Grid 
                container 
                justifyContent='center'
                spacing={0}
                >
              
                  <Grid key={value} item>
                    <Box bgcolor="background.paper" className={classes.boxRoot}>
                      <Paper className={classes.paper}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm container>

                            <Grid item>
                              <Typography variant="h5" className={classes.successHeading} align="left" >
                                {content?.SUCCESSPAGE_HEADING_LABEL}
                              </Typography>
                              <Typography className={classes.vocherHeading} align="left"  >
                                {content?.SUCCESSPAGE_VOUCHER_CODE_LABEL + ":"} {paymentResponse?.voucher_code}
                              </Typography>
                             
                            </Grid>

                          </Grid>
                          <Grid item>
                            <Typography variant="h5" className={classes.successHeading} align="right" >
                              <CheckCircleOutlineIcon className={classes.successIcon} />
                            </Typography>
                          </Grid>
                        </Grid>



                        <Grid container spacing={2}>
                          <Grid className={classes.subHeadingRight} item xs={6}>
                            {content?.SUCCESSPAGE_ORDER_ID_LABEL + " :"}
                          </Grid>
                          <Grid className={classes.subHeadingLeft} item xs={6}>
                            {paymentResponse?.oid}
                          </Grid>

                        </Grid>


                        <Grid container spacing={2}>
                          <Grid className={classes.subHeadingRight} item xs={6}>
                            {content?.SUCCESSPAGE_PLAN_NAME_LABEL + " :"}
                          </Grid>

                          <Grid className={classes.subHeadingLeft} item xs={6}>

                            <div>
                              <div dangerouslySetInnerHTML={{ __html: (prodDescription) }} />
                            </div>

                          </Grid>

                        </Grid>


                        <Grid container spacing={2}>
                          <Grid className={classes.subHeadingRight} item xs={6}>
                            {content?.SUCCESSPAGE_PURCHASED_DATE_LABEL + " :"}
                          </Grid>

                          <Grid className={classes.subHeadingLeft} item xs={6}>
                            {utcDate}
                          </Grid>

                        </Grid>


                        <Grid container spacing={2}>
                          <Grid className={classes.subHeadingRight}  item xs={6}>
                            {content?.SUCCESSPAGE_VOUCHER_VALID_LABEL + " :"}
                          </Grid>

                          <Grid className={classes.subHeadingLeft}  item xs={6}>
                            {expDate}
                          </Grid>

                        </Grid>


                        <Grid container spacing={2}>
                          <Grid className={classes.subHeadingRight}  item xs={6}>
                            {content?.SUCCESSPAGE_AMOUNT_LABEL + " :"}
                          </Grid>
                          <Grid className={classes.subHeadingLeft}  item xs={6}>
                            <div >
                              {selectObj && selectObj[0]?.symbol + ' ' + Number(selectObj && selectObj[0]?.value)}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid className={classes.subHeadingRight}  item xs={6}>
                            {content?.SUCCESSPAGE_TAX_RATE_LABEL + "(" + selectObj[0]?.rate + "%):"}
                          </Grid>

                          <Grid className={classes.subHeadingLeft}  item xs={6}>
                            <div>
                              {selectObj && selectObj[0]?.symbol + ' ' + Number(selectObj && selectObj[0]?.rateValue).toFixed(2)}
                            </div>
                          </Grid>

                        </Grid>

                        <Grid container spacing={2}>
                          <Grid className={classes.subHeadingRight}  item xs={6}>
                            {content?.SUCCESSPAGE_TOTAL_LABEL + " :"}
                          </Grid>

                          <Grid className={classes.subHeadingLeft}  item xs={6}>
                            <div>
                              {selectObj && selectObj[0]?.symbol + ' ' + Number(selectObj && selectObj[0]?.totalValue)}
                            </div>
                          </Grid>
                        </Grid>


                        <Grid item xs={12} >
                          <Typography variant="h5" className={classes.descHeading} align="left" >
                            <div>
                              {objArrlst.map((key: string, i: number) => {
                                return (
                                  <p className={classes.subHeading} dangerouslySetInnerHTML={{ __html: (ReplaceTextFunction(key, "[Product Name]", paymentResponse?.product?.name)) }} />
                                );
                              })}
                            </div>
                          </Typography>
                        </Grid>
                      </Paper>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>)

    } else {

      return (


        <div >
          <Container >
            <Grid  container className={classes.root} spacing={2}>
              <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={0}>
                  <Grid key={value} item>
                    <Box bgcolor="background.paper">
                      <Paper className={classes.paper}>
                        <Grid style={{ width: "auto" }} container spacing={2}>
                          <Grid item xs={12} sm container>
                            <Grid item>
                              <Typography variant="h5" className={classes.successHeading} align="left" >
                                {content?.ERRORPAGE_HEADING_LABEL}
                              </Typography>
                            </Grid>

                          </Grid>
                          <Grid item>
                            <Typography variant="h5" className={classes.successHeading} align="left" >
                              <ErrorOutlineIcon className={classes.errorIcon} />
                            </Typography>
                          </Grid>
                        </Grid>



                        <Grid container spacing={2}>
                          <Grid className={classes.subHeadingRight} item xs={6}>
                            {content?.ERRORPAGE_ORDER_ID_LABEL + " :"}
                          </Grid>
                          <Grid className={classes.subHeadingLeft} item xs={6}>
                            {paymentResponse?.oid}
                          </Grid>
                        </Grid>



                        <Typography
                          className={classes.subHeading}
                          color="primary"
                          variant="h6" >
                          <p>Purchase of  {paymentResponse?.product?.name}&nbsp;
                            Wi-Fi plan failed.</p>
                          <p>{content?.ERRORPAGE_RESPONSE_REASON + ":"} {paymentResponse?.fail_reason}</p>
                        </Typography>


                      </Paper>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>


      )

    }

  }


  const [value, setValue] = React.useState(0);

  return (

    <Grid
      item
      container
      direction="column"
      justify="center"
      alignItems="center"

      classes={{
        root: classes.receiptContainerRoot
      }}
      spacing={2}
    >
      <Grid
        item
        container
        direction="column"
        justify="center"
        alignItems="center"
        classes={{
          root: classes.receiptItemRoot
        }}
        >
        <SuccessVoid />
      </Grid>
      <Typography style={{ marginTop: "25px", height: 250 }} variant="h5" align="center" >
        &nbsp;
      </Typography>
    </Grid>

  )
}


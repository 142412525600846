import React from 'react'
import GlobalStyle from './theme/globalStyle';
import { Helmet } from 'react-helmet'
import { Route, Switch } from 'react-router'
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core';
import { createAirTheme } from "./theme/createTheme";
import { branding } from "./tenant/style";
import { Home } from './pages/Home';
import { Help } from './pages/Help';
import { NotFound } from './pages/Error';
import { Checkout } from './pages/Checkout';
import { Success } from './pages/Success';
import { rootEpic } from './epics/';
import { createEpicMiddleware } from 'redux-observable';
import { rootReducer } from './reducers';
import { createLogger } from 'redux-logger';
import { appActions } from './actions';


declare global {
  var window: Window & typeof globalThis;
}

declare global {
  interface Window {
    __PRELOADED_STATE__: any
  }
}


// hack to typecast to any
const logger = (createLogger as any)();
const epicMiddleware = createEpicMiddleware();
const middlewares = [logger, epicMiddleware];
const store = createStore(rootReducer, applyMiddleware(...middlewares));
epicMiddleware.run(rootEpic);


let preloadedState: any


if (typeof window !== "undefined") {
  setTimeout(() => {
    console.log('window.__PRELOADED_STATE__ preloadedState before timeout:', window?.__PRELOADED_STATE__?.appData?.currentPage)

    preloadedState = window.__PRELOADED_STATE__ || {};
    if (preloadedState) {
      store.dispatch(appActions.setState(preloadedState))
    }

  }, 0)

}


//  Theming
const selectedLocale = 'en';
const { theme, isRTL } = createAirTheme(
  branding,
  selectedLocale
);


const App: React.FC = () => {


  React.useEffect(() => {
    const jssStyles: Element | null | undefined = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);


  return (
    <React.Fragment>
      <GlobalStyle />
      <Helmet>
        <title>{process.env.REACT_APP_NAME}</title>
      </Helmet>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Switch>
            <Route path="/help/" component={Help} />
            <Route path="/" component={Home} exact />
            <Route path="/checkout/:id" component={Checkout} />
            <Route path="/success/" component={Success} />
            <Route component={NotFound} />
          </Switch>
        </ThemeProvider>
      </Provider>
    </React.Fragment>
  )
}
export default App

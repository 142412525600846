import React from 'react'
import { render } from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'



import App from './App'
import * as serviceWorker from './serviceWorker'

/**
 * entry point for client-side local ( dev enviroment only )
 */

render(
  <HelmetProvider>
    <BrowserRouter>
        <App  />
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

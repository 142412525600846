import React, { useState } from "react";
import {
  Grid,
  Button,
  Checkbox,
  TextField,
  FormHelperText,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import { Container } from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { checkoutEmail } from './../tenant/style'

const useStyles = makeStyles(checkoutEmail);



export const CheckoutEmail = (props: any) => {
  const { selectedProduct = {}, form = {}, content = {} } = props;

  const classes = useStyles();
  const Form = () => {
    const initialFormValues = {
      email: "",
      gst: "",
      formSubmitted: false,
      success: false,
    };
    const [values, setValues] = useState(initialFormValues);
    const [errors, setErrors] = useState({} as any);
    const [privacy, setPrivacy] = useState(false);
    //const [gst, setGst] = useState(false);

    const validate: any = (fieldValues = values) => {
      let temp: any = { ...errors };

      if ("email" in fieldValues) {
        temp.email = fieldValues.email ? "" : content?.CHECKOUT_EMAIL_VALID_MSG + "";
        if (fieldValues.email)
          temp.email = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(fieldValues.email)
            ? ""
            : content?.CHECKOUT_EMAIL_VALID_MSG + "";
      }

      if ("gst" in fieldValues) {
        if (fieldValues.gst && fieldValues.gst.length > 15)
          temp.gst = content?.CHECKOUT_GST_VALID_MSG + "";
        else
          temp.gst = "";
      }

      setErrors({
        ...temp,
      });
    };

    const validateButton: any = (fieldValues = values) => {
      let temp: any = { ...errors };

      if ("email" in fieldValues) {
        temp.email = fieldValues.email ? "" : content?.CHECKOUT_EMAIL_VALID_MSG + "";
        if (fieldValues.email)
          temp.email = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(fieldValues.email)
            ? ""
            : content?.CHECKOUT_EMAIL_VALID_MSG + "";
      }

      if ("gst" in fieldValues) {
        if (fieldValues.gst && fieldValues.gst.length > 15)
          temp.gst = content?.CHECKOUT_GST_VALID_MSG + "";
        else
          temp.gst = "";
      }

      if (!privacy) {
        temp.privacy = privacy ? "" : content?.CHECKOUT_POLICY_VALID_MSG + "";
      }

      setErrors({
        ...temp,
      });
    };
    function handleInputValue(e: any) {
      const { name, value } = e.target;
      setValues({
        ...values,
        [name]: value,
      });
      validate({ [name]: value });
    }

    function handleButton(e: any) {
      validateButton(values);
    }

    function emptyFunc(e: any) {
      return true
    }

    const handleSuccess = () => {
      setValues({
        ...initialFormValues,
        formSubmitted: true,
        success: true,
      });
    };

    const handleError = () => {
      setValues({
        ...initialFormValues,
        formSubmitted: true,
        success: false,
      });
    };


    const validateCheckBox: any = () => {
      let temp: any = { ...errors };
      temp.privacy = !privacy ? "" : content?.CHECKOUT_POLICY_VALID_MSG + "";
      setErrors({
        ...temp,
      });
    };

    function handleCheckboxValue(e: any) {
      const { name, value } = e.target;
      setPrivacy(!privacy)
      validateCheckBox();
    }

    const formIsValid = (fieldValues = values) => {
      const isValid = fieldValues.email && Object.values(errors).every((x) => x === "");
      return isValid;
    };


    return (

      <React.Fragment>

        <form
          method="post"
          id="postForm"
          action="/ccavRequestHandler"
          autoComplete="off"
        >

          <Box justifyContent='center' >
            <Container>
              
              <Box display="flex" justifyContent="center">
                <Box className={classes.boxWidthTextBox} height="10" p={1} >
                  <div className={classes.emailHeading}>
                    {content?.CHECKOUT_EMAIL_HEADER_LABEL}
                  </div>
                </Box>
              </Box>

              <div className={classes.gridHeight} >
                &nbsp;
              </div>


              {Object.keys(form).map((key: string, i: number) => {
                const inputType: string =
                  key == "email" || key == "gst" ? "text" : "hidden";
                const inputVal: string = form[key] || "";
                return key == "email" || key == "gst" ? (
                  <div>

                    {i > 0 && (
                      <Grid item>
                        &nbsp;
                      </Grid>
                    )}


                    <Box display="flex" justifyContent="center">
                      <Box className={classes.boxWidthTextBox} height="10" p={1} >
                        <div className={classes.divHeight} style={{ border: errors[key] ? '1px solid red' : '' }}>
                          <TextField
                            type={inputType}
                            name={key}
                            defaultValue={inputVal}
                            variant="outlined"
                            fullWidth
                            onBlur={handleInputValue}
                            className={classes.textBoxStyle}
                            label={(errors && errors[key]) ? errors[key] : (key == "gst" ? content?.CHECKOUT_GST_OPTIONAL_LABEL : key.toUpperCase())}
                            InputProps={{ classes: { root: classes.inputRoot } }}
                            InputLabelProps={{
                              classes: {
                                root: (errors && errors[key]) ? classes.errorLabelRoot : classes.labelRoot,
                                focused: classes.labelFocused
                              }
                            }}
                            margin="normal"
                          />
                        </div>
                      </Box>
                    </Box>


                  </div>
                ) : (
                  <div key={i.toString()}>
                    <input type={inputType} name={key} defaultValue={inputVal} />
                  </div>
                );
              })}


              <div  >
                &nbsp;
              </div>

              <Grid item>
                <div>
                  <FormHelperText className={classes.policyFont} >
                    <Checkbox name={content?.CHECKOUT_PRIVACY} value={privacy} checked={privacy} onChange={handleCheckboxValue} />{content?.CHECKOUT_POLICY_LABEL}&nbsp;<a target="_blank" className={classes.policyFont} href={content?.FOOTER_PRIVACY_POLICY_URL}>{content?.CHECKOUT_PRIVACY_POLICY_LABEL}</a>&nbsp;{content?.CHECKOUT_AND}&nbsp;<a target="_blank" className={classes.policyFont} href={content?.FOOTER_TERM_SALE_URL}>{content?.CHECKOUT_PRIVACY_TERMS_SALE_LABEL}{'.'}</a>
                  </FormHelperText>
                  <FormHelperText className={classes.errorFont}> {errors['privacy']} </FormHelperText>
                </div>
              </Grid>



              <Grid item>
                <Box display="flex" justifyContent="center">
                  <Box className={classes.boxWidthTextBox} p={1} bgcolor="background.paper">
                    <Button
                      variant="contained"
                      fullWidth
                      type={(formIsValid() && privacy) ? "submit" : "button"}
                      onClick={((formIsValid() && privacy) ? emptyFunc : handleButton)}
                      className={classes.buttonTag}
                    >
                      <FormHelperText className={classes.buttonFont}> {content?.CHECKOUT_CONTINUE_LABEL}</FormHelperText>
                    </Button>
                  </Box>
                </Box>
              </Grid>


              <Grid item xs={12} md={12}>
                <Typography
                  align="center"
                  color="primary"
                  variant="h5"
                >
                  <Button
                    classes={{ root: classes.backButton }}
                    variant="contained"
                    href="/"
                    startIcon={<ArrowBack />}
                  >
                   <div>{content?.CHECKOUT_BACK_LABEL}</div> 
                  </Button>

                </Typography>
              </Grid>

            </Container>
          </Box>
        </form>

        <div className={classes.divHeightSpace}>
          &nbsp;
        </div>

      </React.Fragment>
    );
  };

  return <Form />;
};

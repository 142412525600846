import { appConstants, productsConstants, checkoutConstants } from '../constants';

export const currentPage = (id:string) => ({
    type: appConstants.CURRENT_PAGE,
    id
  })

  export const changeSelectedLocale = (payload:string) => ({
    type: appConstants.LOCALE,
    payload
  })

  export const setLocale = (payload:string) => ({
    type: appConstants.SET_LOCALE,
    payload
  })

  export const setLocales = (payload:string) => ({
    type: appConstants.LOCALES,
    payload
  })

  export const setContent = (payload:any) => ({
    type: appConstants.CONTENT,
    payload
  })

  export const setFeatures = (payload:any) => ({
    type: appConstants.FEATURES,
    payload
  })

  export const setState = (payload:any) => ({
    type: appConstants.SET_STATE,
    payload
  })

  export const appActions = {
    setState,
    currentPage,
    changeSelectedLocale,
    setLocales,
    setLocale,
    setFeatures,
    setContent
  };


  export const addProductsPrices = (data:any) => ({
    type: productsConstants.PRODUCTS_PRICES,
    data
  })

  export const  selectedProductSet = (data:any) => ({
    type: checkoutConstants.SELECTED_PRODUCT,
    data
  })

  export const  paymentResponseSet = (data:any) => ({
    type: checkoutConstants.PAYMENT_RESPONSE,
    data
  })

  export const  formSet = (data:any) => ({
    type: checkoutConstants.FORM,
    data
  })

  

  

export const productsActions = {
    addProductsPrices
}

export const checkoutActions = {
  selectedProductSet,
  paymentResponseSet,
  formSet
}
  
import {combineEpics} from 'redux-observable';
import {
    filter,
    mapTo,
    mergeMap,
    tap,
    switchMap,
    catchError,
    map,
    ignoreElements
  } from 'rxjs/operators';
  import {from} from 'rxjs'
  import {productsConstants, appConstants} from '../constants';
  import {appActions, productsActions} from '../actions/';
  import {fetchPost} from '../helpers/loadData';

  export const changeSelectedLocaleEpic = (action$:any, state$:any) =>
  action$.pipe(
    filter((action:any) => action.type === appConstants.LOCALE),
    tap((action:any) => {
      const {currentPage} = state$.value.appData;
      // if the app is on any other page than success refresh the whole page, sending updated locale in url
      if (currentPage != '/success'){
       // changeSelectedLocale(action.payload)

      }
    }),
    mergeMap((action:any)=>{


      const options = {locale:action.payload};
      return from(fetchPost('data/content', options, false, 'appData', 'content'))
      .pipe(
        map((response:any)=> response.data )
      );

    }),
    map((data:any)=> appActions.setContent(data))
    

  )

  const changeSelectedLocale = (id:string)=>{
    const {protocol, host, port, pathname} = window.location || {}
    const url = `${protocol}//${host}${pathname}?locale=${id}`;
    window.location.href = url;
}

export const rootEpic = combineEpics(
    changeSelectedLocaleEpic
)




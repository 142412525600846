import React, { useEffect, useState } from 'react'
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, RootStateOrAny } from 'react-redux'
import Paper from '@material-ui/core/Paper';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ButtonBase from '@material-ui/core/ButtonBase';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { Header } from './../components/Header';
import { Footer } from './../components/Footer';
import { PageWithBackgroundImage } from "../components/pageWithBackgroundImage";
import { help } from './../tenant/style'

declare global {
  var window: Window & typeof globalThis;
}


const useStyles = makeStyles(help);

const HelpMain = (props: any) => {
  
  const classes = useStyles();
  let boolArr = new Array(Object.keys(props.objFaqlst).length).fill(false) || [];
  const [state, setState] = React.useState({ itemArr: boolArr });

  function setFlagExpand(index: any) {
    let { itemArr } = { ...state };
    itemArr[index] = !itemArr[index];
    setState({ ...state, itemArr });
  }

  const expandedIsOpen = (index: any) => {
    let { itemArr } = { ...state };
    return itemArr[index];
  };


  return (
    <Typography variant="h5" align="center" >

      <Accordion id={props.index + ''} className={classes.accordionParent} >
        <AccordionSummary
          aria-controls="panel1c-content"
          id={"panel1c-header"}
          onClick={() => setFlagExpand(props.index)}
        >
          <div className={classes.firstcolumn}>
            <div className={classes.expandedIconTag}  >{expandedIsOpen(props.index) ? <RemoveIcon /> : <AddIcon />}</div>
          </div>
          <div className={classes.column}>
            <div className={classes.accordlabel} dangerouslySetInnerHTML={{ __html: (props.title) }} />
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.accordionDetailsParent} >
            {props.items.map((ikey: any, index: number) => {
              return (
                <HelpChild question={ikey?.question} answer={ikey?.answer} answers={ikey?.answers} />
              )
            })}
          </div>
        </AccordionDetails>

      </Accordion>

    </Typography>
  );

}


const FaqCardDeatils = (props: any) => {
  const classes = useStyles();

  return (
    <div className={classes.box}>
      <div className={classes.boxArrowBorder} >
        <div className={classes.boxArrowBackground}></div>
      </div>
      <div className={classes.boxContent}>

        {props.answer && (
          <div className={classes.accordionDetailsChild} dangerouslySetInnerHTML={{ __html: (props.answer) }} />
        )}

        {props.answers && (
          <div className={classes.accordionDetailsChildList} dangerouslySetInnerHTML={{ __html: (props.answers) }} />
        )}
        
      </div>
    </div>
  )

}




const HelpChild = (props: any) => {
  const classes = useStyles();

  return (
    <Accordion key={props.index + ''} className={classes.accordionChild}  >
      <AccordionSummary >
        <div className={classes.accordionSummaryChild}
          dangerouslySetInnerHTML={{ __html: (props.question) }} />
      </AccordionSummary>
      <AccordionDetails >
        {props.answer && props.answer.length > 0 && (
          <FaqCardDeatils answer={props.answer} />
        )}

        {props.answers && props.answers.length > 0 && (
          <FaqCardDeatils answers={props.answers} />
        )}
      </AccordionDetails>
    </Accordion>
  )

}


const MailBoxChild = (props: any) => {
  const classes = useStyles();
  const { content } = props;
  return (
    <Box className={classes.mailBoxTag} justifyContent='center' >

      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.gridMailTag}>
        <Box className={classes.boxWidthTextBox} justifyContent='center' >
          <Paper className={classes.bgColor} >
            <Typography variant="h6" className={classes.faqSubIcon} align="center" >
              <ButtonBase >
                <MailOutlinedIcon className={classes.mailOutlinedIconTag} />
              </ButtonBase>
            </Typography>
            <Typography className={classes.mailHeading} align="center" >
              {content?.HELP_TELL_US}
            </Typography>
            <Typography className={classes.mailSubHeading} align="center" >
              {content?.SEND_US_EMAIL_AT}<br></br><a className={classes.mailSubHeadingLink} href={'mailto:' + content?.HELP_EMAIL_ADDRESS}>{content?.HELP_EMAIL_ADDRESS}</a>
            </Typography>
            <div>
              &nbsp;
            </div>
          </Paper>
        </Box>
      </Grid>
    </Box>
  )

}


const FAQCard = (props: any) => {
  const classes = useStyles();
  const { content } = props;
  function scrollUp() {
    let pageHeight = window.innerHeight;

    window.scrollTo({
      top: pageHeight + 200, //document.documentElement.scrollHeight - 400,
      behavior: 'smooth'

    });
  }

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.faqCardGrid}
      >        <Box className={classes.paper} >
          <table className={classes.verticalAlign}>
            <tr>
              <td className={classes.helpOutTD1}>
                <HelpOutlineIcon onClick={() => scrollUp()} className={classes.helpOutIcon} />
              </td>
              <td className={classes.helpOutTD2} >
                <div className={classes.label1}  >
                  {content?.FAQ_LABEL}
                </div>
                <div className={classes.label2}  >
                  {content?.HELP_FAQ_LABEL}
                </div>
              </td>
            </tr>
          </table>
        </Box>
      </Grid>
    </div>

  )

}


export const Help = (props: any) => {

  const classes = useStyles();

  // state returned server-side
  const ssrState = props?.staticContext?.location || {};
  // isomorphic state 
  const [overallState, setOverallState] = useState(ssrState);

  // state from client side 
  const clientState: any = useSelector((state: RootStateOrAny) => {
    return state

  });

  const { appData: { content = {}, domain = '' } = {} } = overallState;
  //content = mcontent; 
  
  useEffect(() => {
    if (clientState && content) {
      setOverallState(clientState);
    }
  }, [clientState])

  const objFaqlst = content?.FAQ_DATA || [];

  function scrollUp() {
    let pageHeight = window.innerHeight;

    window.scrollTo({
      top: pageHeight + 200, //document.documentElement.scrollHeight - 400,
      behavior: 'smooth'

    });
  }

  return (
    <PageWithBackgroundImage content={content} domain={domain} className={classes.root}>
      <Header content={content} domain={domain} />
      <Container>
        <Typography className={classes.helpHeading} align="center" >
          {content?.HELP_HEADER_LABEL}
        </Typography>
        <FAQCard content={content} />
        <Box display="flex" justifyContent="center">
          <Box className={classes.gridWidthTextBox} height="10" p={1} >
            <div className={classes.faqHeading}  >
              {content?.FAQ_LABEL}
            </div>
            <div className={classes.faqSubHeading}  >
              {content?.HELP_FAQ_INFO_LABEL}
            </div>
            <div  >
              {''}
            </div>
          </Box>
        </Box>
        <Box className={classes.rootBox} justifyContent='center' >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.gridTag}>
            <Box className={classes.boxWidthTextBox} justifyContent='center' >
              {objFaqlst.map((obj: any, index: number) => {
                return (<HelpMain objFaqlst={objFaqlst} index={index} title={obj.title} items={obj.items} />)
              })}
            </Box>
          </Grid>
        </Box>
        <MailBoxChild content={content} />
        <div className={classes.divHeightSpace}>
          &nbsp;
        </div>
      </Container>
      <Footer content={content} />
    </PageWithBackgroundImage>
  )

}

import React from "react";
import { useTheme } from "@material-ui/styles";

export interface DefaultTheme {
  palette?:any,
  headerBackgroundColor?:string
}

export default function CloseButton({ fill='', size='', ...props }) {
  const theme:DefaultTheme = useTheme();
  return (
    <svg viewBox="0 0 44 44" {...props}>
      <g id="Foundations" strokeWidth="2" fill="none" fillRule="evenodd">
        <g id="Icons" transform="translate(-678.000000, -633.000000)">
          <g id="Group-62" transform="translate(679.000000, 634.000000)">
            <g id="Group-261">
              <path
                fill={props?.stroke || theme?.palette?.iconColor?.primary}
                d="M23.1701885,21.0010155 C24.4768704,19.644326 24.3123929,19.6879919 25.5155149,18.2307692 C27.0333778,16.3683676 27.7704804,15.1518152 28.9979694,13 C26.8465639,14.2287383 25.6312583,14.9639502 23.7671807,16.484133 C22.3112507,17.6844377 22.3569389,17.5199289 21.0005076,18.8268596 C19.6420458,17.5199289 19.6897646,17.6844377 18.2348499,16.486164 C16.3707723,14.9659812 15.1544514,14.2287383 13.0030459,13 C14.2325655,15.1518152 14.9645917,16.3683676 16.4875309,18.2328002 C17.6886224,19.6879919 17.5231296,19.644326 18.8287962,20.9989845 C17.5241449,22.3566895 17.6916683,22.3109926 16.4905768,23.7702463 C14.9696681,25.6356943 14.2366267,26.8471693 13,29 C15.1524208,27.7671998 16.3656958,27.0309723 18.2307888,25.511805 C19.687734,24.3084539 19.6420458,24.4739782 21.0005076,23.1711094 C22.3589695,24.4739782 22.3112507,24.3094694 23.7712418,25.5097741 C25.6353195,27.0309723 26.8465639,27.7671998 29,29 C27.7643886,26.8471693 27.0313472,25.6356943 25.5084079,23.7702463 C24.3083317,22.3109926 24.4748398,22.3566895 23.1701885,21.0010155"
                id="Fill-237"
              ></path>
              <path
                d="M42,21 C42,32.598 32.5977761,42 20.9995,42 C9.40222386,42 0,32.598 0,21 C0,9.401 9.40222386,0 20.9995,0 C32.5977761,0 42,9.401 42,21 L42,21 Z"
                id="Stroke-238"
                strokeWidth="2"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

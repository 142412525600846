
import React from 'react'
import { Grid, Paper, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from "react-redux";
import { productsGrid } from './../tenant/style';

const useStyles = makeStyles(productsGrid);


export const ProductsGrid = (props: any) => {

  const { product, content } = props;
  const classes = useStyles();
  const locale = useSelector((state: any) => state?.appData?.locale)
  const currency = useSelector((state: any) => state?.appData?.currency);

  const priceObj = product?.price?.allCurrencies && product?.price?.allCurrencies.length > 0 && product?.price?.allCurrencies.filter((o: any) => Object.keys(o).some(k => { return (currency?.toString() === o.currencyCode + "") }));
  const totalCostLabel = Intl.NumberFormat(props?.content?.LOCALE, { minimumFractionDigits: 2 }).format(Number(priceObj && priceObj[0]?.value));
  const symbol = priceObj && priceObj[0]?.symbol;

  return (

    <Grid item
      xs={12}
      md={6}    
      container>
      <a className={classes.productItemInnerContainer} href={`checkout/${product.id}?locale=${locale}&cc=${currency}`} >
        <Paper
          classes={{
            root: classes.productListContainerRoot
          }}>
          <table className={classes.tableTag}>
            <tr>
              <td>
                <div className={classes.cardTitle}>{product.name}</div>
              </td>
              <td><div className={classes.priceTag}>{symbol}&nbsp;{totalCostLabel}</div></td>
            </tr>
            <tr>
              <td colSpan={2} >
                <div className={classes.cardDesc} dangerouslySetInnerHTML={{ __html: (product?.description || '') }} />
              </td>
            </tr>
            <tr>
              <td colSpan={2} >
                <div className={classes.dividerTag} />
                <div className={classes.cardPrice}>{symbol}&nbsp;{totalCostLabel}</div>
              </td>
            </tr>

          </table>
          <Grid item>
            <Button variant="contained" fullWidth={true}
              className={classes.purchaseButton}
            >BUY NOW
            </Button>
          </Grid>

        </Paper>
      </a>
    </Grid>

  )

}



import React from "react";
//import { useSelector, RootStateOrAny } from "react-redux";
import { Grid } from "@material-ui/core";
import moment from 'moment';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { footer } from './../tenant/style';


function ReplaceTextFunction(inputStr: any, replaceStr: any, outputStr: any) {
  return inputStr && inputStr.toString().replace(replaceStr, outputStr);
}

export const Footer = (props: any) => {
  const useStyles = makeStyles(footer);
  const classes = useStyles();
  const { content } = props;
  var currentYear = moment().format('YYYY');

  return (
    <div className={classes.divBottom}>
      <div className={classes.bgColor} >
        &nbsp;
      </div>

      <div className={classes.bgColor}>
        &nbsp;
      </div>
      <div className={classes.bgColor}>
        &nbsp;
      </div>

      <div className={classes.footer} >
        <div className={classes.divHeight} >
          <div className={classes.bgColor}>
            &nbsp;
          </div>
          <Grid container className={classes.divBottom} spacing={0} justify="center">
            <a target="_blank" href={content?.FOOTER_TERM_SERVICE_URL}><div className={classes.linkTag} dangerouslySetInnerHTML={{ __html: (content?.FOOTER_TERM_SERVICE_LABEL) }} /></a>
            <a target="_blank" href={content?.FOOTER_TERM_SALE_URL}><div className={classes.linkTag} dangerouslySetInnerHTML={{ __html: (content?.FOOTER_TERM_SALE_LABEL) }} /></a>
            <a target="_blank" href={content?.FOOTER_PRIVACY_POLICY_URL}><div className={classes.linkTag} dangerouslySetInnerHTML={{ __html: (content?.FOOTER_PRIVACY_POLICY_LABEL) }} /></a>
            <a href={content?.FOOTER_HELP_URL}><div className={classes.linkTag} dangerouslySetInnerHTML={{ __html: (content?.FOOTER_HELP_LABEL) }} /></a>
          </Grid>
          <div className={classes.bgColor}>
            &nbsp;
          </div>
          <div className={classes.copyRight} >
            <div />
            <p dangerouslySetInnerHTML={{ __html: (ReplaceTextFunction(content?.FOOTER_COPYRIGHT_CONTENT, "[currentYear]", currentYear)) }} />
          </div>
        </div>
      </div>

    </div>
  );
};


import { withStyles, createStyles } from "@material-ui/core/styles";
import React from "react";
import { headerLogoStyle } from './../tenant/style';

export const HeaderLogo = withStyles(
  (headerLogoStyle),
  { name: "HeaderLogo" }
)((props: any) => {
  const { to, classes, content, domain } = props;
  const logo = <img className={classes.logoBackgroundImage} src={`${domain}/tenant/img/logo.png`} />; 
  if (to) {
    return (
      <div>
        <a href={`${to}`} >
          {logo}
        </a>
      </div>
    );
  } else {
    return logo;
  }
});



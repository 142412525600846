import { createStyles } from '@material-ui/core/styles';

const header = (theme: any = {}) => createStyles({

  "LogoHeader": {
    "marginTop": "-15px"
  },
  "mobileTag": {
    "marginTop": "-30px"
  },
  "menuColor": {
    "color": theme.palette.primary.contrastText
  },
  "menuContentColor": {
    "color": "#FFF"
  },
  "smDownTag": {
    "display": "block"
  },
  "mdUpTag": {
    "display": "none"
  },
  [theme.breakpoints.up("md")]: {
    "LogoHeader": {
      "marginTop": "20px"
    },
    "smDownTag": {
      "display": "none"
    },
    "mdUpTag": {
      "display": "block"
    }
  }

});

const headerLogoStyle = (theme: any = {}) => createStyles({
  "logoBackgroundImage": {
    "width": "132px",
    "height": "41px",
    "marginTop": "-12px",
    "backgroundSize": "contain",
    "backgroundRepeat": "no-repeat",
    "display": "block",
    "float": 'left'
  },
  [theme.breakpoints.up("md")]: {
    "logoBackgroundImage": {
      "display": "block",
      "float": "left",
      "height": "83px",
      "width": "296px",
      "backgroundSize": "contain"
    }
  }
});

const footer = (theme: any = {}) => createStyles({
  "footer": {
    "backgroundColor": "rgba(70,20,60,.08)",
    "width": "`100%`",
    "bottom": 0
  },
  "bgColor": {
    "backgroundColor": "transparent"
  },
  "divHeight": {
    "height": 148
  },
  "divBottom": {
    "bottom": 0
  },
  "linkTag": {
    "color": "#000",
    "fontSize": "12px",
    "lineHeight": "18px",
    "textDecoration": "underline",
    "fontFamily": "Verdana,Geneva,sans-serif",
    "paddingRight": "5px",
    "paddingLeft": "5px"
  },
  "copyRight": {
    "paddingLeft": "10px",
    "paddingRight": "10px",
    "marginTop": "25px",
    "color": "#000",
    "marginBottom": "1px",
    "fontSize": "12px",
    "lineHeight": "18px",
    "height": 70,
    "fontFamily": "Verdana,Geneva,sans-serif",
    "text-align": "center"
  }

});


const branding = {
  "colors": {
    "primary-color": "#46143C",
    "heading-on-background-image-color": "#ffffff",
    "header-menu-link-color": "#46143C",
    "product-box-background-color": "#46143C",
    "product-box-text-color": "#ffffff",
    "product-box-action-button-background-color": "#ffffff",
    "product-box-action-button-text-color": "#46143C",
    "header-background-color": "rgba(0, 0, 0, 0)"
  },
  "font": {
    "id": 10,
    "type": "system",
    "fontFamilyValues": {
      "font-family": "\"Verdana, Geneva, sans-serif\"",
      "font-family-light": "\"Verdana, Geneva, sans-serif\"",
      "font-family-regular": "\"Verdana, Geneva, sans-serif\"",
      "font-family-medium": "\"Verdana, Geneva, sans-serif\"",
      "font-family-semibold": "\"Verdana, Geneva, sans-serif\"",
      "font-family-bold": "\"Verdana, Geneva, sans-serif\"",
      "font-family-sans-serif": "\"Verdana, Geneva, sans-serif\""
    },
    "name": "Verdana",
    "title": "Verdana"
  }
}

const airlineThemePalette = {
  "primary": {
    "main": "#46143C",
    "contrastText": "#ffffff"
  },
  "secondary": {
    "main": "#cfcfcf",
    "contrastText": "#ffffff"
  },
  "bodyBackground": {
    "main": "#ffffff",
    "contrastText": "#46143C"
  },
  "headerBackground": {
    "contrastText": "#46143C"
  },
  "footerBackground": {
    "main": "#ffffff"
  },
  "iconColor": {
    "primary": "#46143C",
    "secondary": "#46143C"
  }
};

const themeBack = {
  "palette": {
    "primary": {
      "main": "#556cd6"
    },
    "secondary": {
      "main": "#19857b"
    },
    "error": {
      "main": "#ff1744"
    },
    "background": {
      "default": "#fff"
    }
  }
}

const colors = {
  "contrastTextColor": 'rgba(0,0,0,0)',
  "mobilrBasedonBackgroundColor": '#000000',
  "languageSelectorColor": "#000000"
}


const home = (theme: any = {}) => createStyles({
  "root": {
    "flexGrow": 1
  },
  "h5": {
    "color": "#46143C",
    "fontFamily": "Verdana,Geneva,sans-serif",
    "lineHeight": 1,
    "fontWeight": 600,
    "fontStyle": "normal",
    "fontSize": "24px",
    "textAlign": "center",
    "margin": "auto",
    "marginBottom": 18
  },
  "h6": {
    "color": "#46143C",
    "fontSize": "16px",
    "fontFamily": "Verdana,Geneva,sans-serif",
    "fontWeight": 700,
    "fontStyle": "normal",
    "lineHeight": 1,
    "textAlign": "center",
    "maxWidth": "850px",
    "margin": "auto",
    "paddingBottom": "20px"
  },
  "boxTag": {
    "marginTop": "auto",
    "width": "auto"
  },
  "rootGridTag": {
    "border": "1px solid transparent",
    "width": "100%"
  },
  "gridTag": {
    "padding": "auto"
  },
  "boxMargin": {
    "marginTop": "20px"
  },
  "smDownTag": {
    "display": "block"
  },
  "mdUpTag": {
    "display": "none"
  },
  [theme.breakpoints.up("md")]: {
    "h5": {
      "fontSize": "45px",
      "lineHeight": "55px",
      "marginTop": "80px"
    },
    "h6": {
      "fontWeight": 600,
      "fontSize": "30px",
      "lineHeight": "55px"
    },
    "smDownTag": {
      "display": "none"
    },
    "mdUpTag": {
      "display": "block"
    }
  },
  "divHeightSpace": {
    "height": "160px"
  },
  [theme.breakpoints.up("lg")]: {
    "boxTag": {
      "marginTop": "55px",
      "width": "80%"
    },
    "gridTag": {
      "padding": theme.spacing(8)
    },
    "smDownTag": {
      "display": "none"
    },
    "mdUpTag": {
      "display": "block"
    }
  }

});

const help = (theme: any = {}) => createStyles({
  "root": {
    "flexGrow": 1
  },
  "paper": {
    "textAlign": "center",
    "color": "theme.palette.text.secondary",
    "width": "100%",
    "backgroundColor": "#46143C"
  },
  "helpOutIcon": {
    "verticalAlign": "middle",
    "fontSize": 30,
    "color": "#FFF"
  },
  "helpOutTD1": {
    "paddingLeft": "20px",
    "paddingRight": "10px",
    "verticalAlign": "middle"
  },
  "helpOutTD2": {

  },
  "label1": {
    "color": "#fff",
    "fontFamily": "Verdana,Geneva,sans-serif",
    "fontWeight": 500,
    "fontStyle": "normal",
    "fontSize": "16px",
    "lineHeight": "30px",
    "textAlign": "left"
  },
  "label2": {
    "color": "#fff",
    "fontFamily": "Verdana,Geneva,sans-serif",
    "fontWeight": 500,
    "fontSize": "12px",
    "opacity": .5,
    "width": "auto",
    "whiteSpace": "pre-wrap",
    "overflow": "hidden",
    "textOverflow": "ellipsis",
    "textAlign": "left",
    "paddingBottom": "10px"
  },
  "boxWidthTextBox": {
    "width": "99%"
  },
  "gridWidthTextBox": {
    "width": "99%"
  },
  "helpHeading": {
    "color": "#46143C",
    "lineHeight": .5,
    "textAlign": "center",
    "fontFamily": "Verdana, Geneva, sans-serif",
    "fontWeight": 600,
    "fontStyle": "normal",
    "fontSize": "25px",
    "marginTop": "10px"
  },
  "faqHeading": {
    "color": "#000",
    "textAlign": "center",
    "fontFamily": "Verdana, Geneva, sans-serif",
    "fontWeight": 600,
    "fontStyle": "normal",
    "fontSize": "20px",
    "lineHeight": "25px",
    "marginTop": "50px"
  },
  "faqSubHeading": {
    "color": "#7e7e7e",
    "fontSize": "14px",
    "lineHeight": "18px",
    "textAlign": "center",
    "marginTop": "15px",
    "fontFamily": "Verdana, Geneva, sans-serif"
  },
  "bgColor": {
    "backgroundColor": "#FFF"
  },
  "faqSubIcon": {
    "color": "#7e7e7e",
    "fontSize": "14px",
    "lineHeight": "18px",
    "textAlign": "center",
    "marginTop": "15px",
    "fontFamily": "Verdana, Geneva, sans-serif",
    "backgroundColor": "#FFF"
  },
  "details": {
    "alignItems": "center"
  },
  "column": {
    "flexBasis": "90%"
  },
  "firstcolumn": {
    "flexBasis": "7%",
    "textAlign": "left"
  },
  "accordlabel": {
    "color": "#46143c",
    "fontFamily": "Verdana,Geneva,sans-serif",
    "fontWeight": 500,
    "fontStyle": "normal",
    "fontSize": "16px",
    "lineHeight": "20px",
    "textAlign": "left"
  },
  "mailHeading": {
    "color": "#000",
    "textAlign": "center",
    "fontFamily": "Verdana, Geneva, sans-serif",
    "fontWeight": 550,
    "fontStyle": "normal",
    "fontSize": "18px",
    "marginTop": "20px"
  },
  "mailSubHeading": {
    "color": "#000",
    "textAlign": "center",
    "fontFamily": "Verdana, Geneva, sans-serif",
    "fontWeight": 550,
    "fontStyle": "normal",
    "fontSize": "12px",
    "marginTop": "25px"
  },
  "accordionParent": {
    "backgroundColor": "#FFF",
    "marginTop": "20px",
    "width": "auto",
    "zIndex": 200
  },
  "accordionDetailsParent": {
    "width": "100%",
    "margin": "auto",
    "marginTop": "-20px"
  },
  "accordionChild": {
    "backgroundColor": "#FFF",
    "width": "auto",
    "boxShadow": "none"
  },
  "accordionSummaryChild": {
    "color": "#46143c",
    "fontSize": "14px",
    "fontFamily": "Verdana,Geneva,sans-serif",
    "& ul": {
      "padding": 0,
      "margin": 0,
      "textAlign": "left"
    }
  },
  "accordionDetailsChild": {
    "paddingLeft": "20px",
    "paddingRight": "10px",
    "color": "#000",
    "fontSize": "14px",
    "textAlign": "left",
    "fontFamily": "Verdana,Geneva,sans-serif",
    "lineHeight": "25px",
    "& p": {
      "padding": 0,
      "margin": 0,
      "textAlign": "left"
    }
  },
  "accordionDetailsChildList": {
    "paddingLeft": "20px",
    "paddingRight": "10px",
    "color": "#000",
    "fontSize": "14px",
    "textAlign": "left",
    "fontFamily": "Verdana,Geneva,sans-serif",
    "lineHeight": "25px",
    "& p": {
      "padding": 0,
      "margin": 0,
      "color": "#000",
      "textAlign": "left",
      "fontFamily": "Verdana,Geneva,sans-serif",
      "lineHeight": "25px",
      "display": "list-item",
      "listStyleType": "disc",
      "listStylePosition": "inside"
    },
    "& div": {
      "padding": 0,
      "margin": 0,
      "color": "#000",
      "fontSize": "16px",
      "textAlign": "left",
      "fontFamily": "Verdana,Geneva,sans-serif",
      "lineHeight": "25px"
    }
  },
  "mailOutlinedIconTag": {
    "color": "#000",
    "verticalAlign": "middle",
    "fontSize": 80
  },
  "mailSubHeadingLink": {
    "color": "#46143c"
  },
  "gridTag": {
    "border": "1px solid transparent",
    "width": "100%"
  },
  "gridMailTag": {
    "border": "1px solid transparent",
    "width": "100%"
  },
  "mailBoxTag": {
    "width": "100%",
    "marginTop": "25px"
  },
  "expandedIconTag": {
    "color": "#000"
  },
  "faqCardGrid": {
    "width": "100%",
    "marginTop": "80px"
  },
  "verticalAlign": {
    "verticalAlign": "middle"
  },
  "rootBox": {
    "width": "100%",
    "backgroundColor": "#F0ECEF"
  },
  "rootBoxOne": {
    "width": "100%"
  },
  "rootPaperTag": {
    "width": "100%",
    "boxShadow": "none"
  },
  "divHeightSpace": {
    "height": "160px"
  },
  [theme.breakpoints.up("md")]: {
    "paper": {
      "width": "350px",
      "paddingTop": "15px",
      "paddingBottom": "10px"
    },
    "helpOutIcon": {
      "fontSize": 48
    },
    "helpOutTD1": {
      "paddingRight": "5px"
    },
    "helpOutTD2": {
      "paddingLeft": "10px",
      "paddingRight": "20px"
    },
    "label2": {
      "fontSize": "16px"
    },
    "helpHeading": {
      "paddingBottom": "50px",
      "lineHeight": 1,
      "fontSize": "45px",
      "marginTop": "50px"
    },
    "faqHeading": {
      "fontSize": "34px"
    },
    "faqSubHeading": {
      "fontSize": "22px",
      "lineHeight": "28px",
      "fontWeight": 500
    },
    "boxWidthTextBox": {
      "width": "75%"
    },
    "gridWidthTextBox": {
      "width": "50%"
    },
    "accordionSummaryChild": {
      "fontSize": "16px"
    },
    "accordionDetailsChild": {
      "fontSize": "16px"
    },
    "accordionDetailsChildList": {
      "fontSize": "16px"
    }
  },
  "box": {
    "position": "relative"
  },
  "boxArrowBorder": {
    "position": "absolute",
    "top": "-9px; left: 23px",
    "borderLeft": "10px solid transparent",
    "borderRight": "10px solid transparent",
    "borderBottom": "10px solid #bebebe",
    "width": 0,
    "height": 0
  },
  "boxArrowBackground": {
    "position": "absolute",
    "top": 0,
    "left": "-10px",
    "borderTop": "1px solid transparent",
    "borderLeft": "10px solid transparent",
    "borderRight": "10px solid transparent",
    "borderBottom": "10px solid #FFF",
    "width": 0,
    "height": 0
  },
  "boxContent": {
    "padding": "10px",
    "border": "1px solid #bebebe",
    "borderRadius": 0,
    "boxShadow": "0 1px 3px rgba(200, 200, 200, 0.7)",
    "overflow": "hidden"
  },


});


const checkout = (theme: any = {}) => createStyles({

  "gridRoot": {
    "width": "100%",
    "marginTop": theme.spacing(-1),
    "marginRight": "0px",
    "marginBottom": "0px",
    "marginLeft": "0px"
  },
  "spaceHeight": {
    "height": "170px"
  }

});


const checkoutEmail = (theme: any = {}) => createStyles({

  "root": {
    "flexGrow": 1
  },
  "emailHeading": {
    "color": "#000",
    "textAlign": "center",
    "fontFamily": "Verdana, Geneva, sans-serif",
    "fontWeight": 600,
    "fontStyle": "normal",
    "fontSize": "20px"
  },
  "boxWidthTextBox": {
    "width": "100%"
  },
  "textBoxStyle": {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      "border": "none",
      "color": "#000",
      "height": 15,
      "borderColor": "#e12525",
      "backgroundColor": "#FFF",
      "marginTop": "0px"
    }
  },
  "divHeight": {
    "height": "64px",
    "backgroundColor": "#FFF",
    "border": "1px solid #dedede",
    "boxShadow": "0 20px 40px -26px #959595"
  },
  "gridHeight": {
    "display": "none"
  },
  "labelRoot": {
    "display": "block",
    "color": "#000",
    "fontFamily": "Verdana, Geneva, sans-serif",
    "fontSize": "13px",
    "fontWeight": 600,
    "lineHeight": "24px",
    "textAlign": "left",
    "&$labelFocused": {
      "fontFamily": "Verdana, Geneva, sans-serif",
      "fontSize": "18px",
      "lineHeight": "20px",
      "letterSpacing": 0,
      "color": "rgba(93,93,93,.7)",
      "whiteSpace": "nowrap",
      "maxWidth": "90%",
      "overflow": "hidden",
      "textAlign": "left"
    }
  },
  "errorLabelRoot": {
    "fontFamily": "Verdana, Geneva, sans-serif",
    "display": "block",
    "color": "#e12525",
    "fontSize": "16px",
    "fontWeight": 500,
    "lineHeight": 1,
    "textAlign": "left",
    "marginTop": "-5px",
    "&$labelFocused": {
      "fontFamily": "Verdana, Geneva, sans-serif",
      "display": "block",
      "color": "#e12525",
      "fontSize": "20px",
      "fontWeight": 500,
      "lineHeight": 1,
      "textAlign": "left",
      "marginTop": "-5px"
    }
  },
  "policyFont": {
    "color": "#7e7e7e",
    "backgroundColor": "transparent",
    "textAlign": "center",
    "fontFamily": "Verdana, Geneva, sans-serif",
    "fontSize": "12px"
  },
  "errorFont": {
    "color": "#e12525",
    "backgroundColor": "transparent",
    "textAlign": "center",
    "fontFamily": "Verdana, Geneva, sans-serif",
    "fontSize": "14px",
    "fontWeight": 500
  },
  "buttonFont": {
    "textAlign": "center",
    "fontFamily": "Verdana, Geneva, sans-serif",
    "color": "#FFF",
    "fontSize": "16px",
    "fontWeight": 600,
    "backgroundColor": "#46143C",
    "textTransform": "none"
  },
  "inputRoot": {
    "fontSize": "16px",
    "fontWeight": 700,
    "color": "#000",
    "fontFamily": "Verdana, Geneva, sans-serif"
  },
  "labelFocused": {

  },
  "backButton": {
    "backgroundColor": "#d9e3e8",
    "color": "#46143c",
    "borderRadius": "100px",
    "fontSize": "17px",
    "lineHeight": "15px",
    "fontWeight": 400,
    "fontFamily": "Verdana, Geneva, sans-serif",
    "letterSpacing": 0,
    "textTransform": "none",
    "marginTop": "20px",
    "padding": "13px 23px",
    "& div": {
      "fontSize": "17px",
      "fontFamily": "Verdana,Geneva,sans-serif",
      "lineHeight": "15px",
      "fontWeight": 400,
      "letterSpacing": 0,
      "textTransform": "none"
    }
  },
  [theme.breakpoints.up("sm")]: {
    "boxWidthTextBox": {
      "width": "65%"
    }
  },
  "buttonTag": {
    "backgroundColor": "#46143C",
    "color": "#FFF",
    "textAlign": "center",
    "verticalAlign": "middle",
    "fontFamily": "Verdana, Geneva, sans-serif",
    "border": "none",
    "height": "54px",
    "&:hover": {
      "backgroundColor": "#46143C"
    }
  },
  "divHeightSpace": {
    "height": "120px"
  },
  [theme.breakpoints.up("md")]: {
    "emailHeading": {
      "fontSize": "34px",
      "lineHeight": "41px",
      "marginTop": "20px"
    },
    "boxWidthTextBox": {
      "width": "50%"
    },
    "divHeight": {
      "height": "84px",
      "backgroundColor": "#FFF"
    },
    "gridHeight": {
      "display": "block",
      "height": "30px"
    },
    "labelRoot": {
      "fontSize": "18px",
      "&$labelFocused": {
        "fontSize": "18px"
      }
    },
    "errorLabelRoot": {
      "fontSize": "18px",
      "&$labelFocused": {
        "fontSize": "20px"
      }
    },
    "errorFont": {
      "fontSize": "15px"
    },
    "buttonFont": {
      "fontSize": "22px",
      "fontWeight": 700
    },
    "inputRoot": {
      "fontSize": "20px"
    },
    "backButton": {
      "marginTop": "20px",
      "fontSize": theme.typography.pxToRem(15)
    },
    "buttonTag": {
      "height": "80px"
    }
  },
  [theme.breakpoints.up("lg")]: {
    "boxWidthTextBox": {
      "width": "45%",
    }
  }

});

const dropDownElement = (theme: any = {}) => createStyles({
  "icon": {
    "padding": theme.spacing(1)
  },
  "buttonBase": {
    "color": "#000000"
  },
  "divTag": {
    "marginTop": "25px"
  },
  "gridTag": {
    "border": "1px solid transparent",
    "width": "100%"
  },
  "buttonText": {
    "color": "#46143c",
    "fontFamily": "Verdana,Geneva,sans-serif",
    "fontStyle": "normal",
    "fontSize": "26px",
    "lineHeight": "32px",
    "cursor": "pointer",
    "textDecoration": "inherit",
    "textAlign": "center"
  },
  [theme.breakpoints.up("md")]: {
    "buttonText": {
      "fontWeight": 700,
      "fontStyle": "normal",
      "fontSize": "16px",
      "lineHeight": "20px"
    }
  }
});

const orderSummary = (theme: any = {}) => createStyles({

  "root": {
    "flexGrow": 1
  },
  "td1": {
    "width": "auto"
  },
  "td2": {
    "width": "auto"
  },
  "td3": {
    "width": "24px"
  },
  "ptitle": {
    "fontFamily": "Verdana,Geneva,sans-serif",
    "fontWeight": 500,
    "fontStyle": "normal",
    "fontSize": "14px",
    "lineHeight": "24px",
    "letterSpacing": "-.26px",
    "color": "rgba(93,93,93,.7)",
    "borderBottom": 0,
    "border": "none"
  },
  "pHeading": {
    "fontFamily": "Verdana,Geneva,sans-serif",
    "fontWeight": 600,
    "fontStyle": "normal",
    "fontSize": "15px",
    "lineHeight": "24px",
    "letterSpacing": "-.26px",
    "color": "#46143c",
    "borderBottom": 0,
    "border": "none",
    "marginBottom": "20px"
  },
  "pHeadingLast": {
    "fontFamily": "Verdana,Geneva,sans-serif",
    "fontWeight": 600,
    "fontStyle": "normal",
    "fontSize": "15px",
    "lineHeight": "24px",
    "letterSpacing": "-.26px",
    "color": "#46143c",
    "flexShrink": 0,
    "textAlign": "center",
    "borderBottom": 0,
    "border": "none"
  },
  "pDetails": {
    "fontFamily": "Verdana,Geneva,sans-serif",
    "fontWeight": 500,
    "fontStyle": "normal",
    "fontSize": "14px",
    "lineHeight": "24px",
    "letterSpacing": "-.26px",
    "color": "rgba(93,93,93,.7)",
    "borderBottom": 0,
    "border": "none"
  },
  "primaryHeading": {
    "color": "#46143C",
    "width": "25%",
    "fontWeight": 400,
    "fontSize": "14px",
    "letterSpacing": "-.29px",
    "fontFamily": "Verdana,Geneva,sans-serif"
  },
  "primaryHeadingLastChild": {
    "color": "#46143C",
    "width": "25%",
    "fontWeight": 400,
    "fontSize": "14px",
    "letterSpacing": "-.29px",
    "fontFamily": "Verdana,Geneva,sans-serif",
    "borderBottom": 0,
    "border": "none"
  },
  "accordionTag": {
    "backgroundColor": "#FFF",
    "width": "100%",
    "boxShadow": "0 20px 40px -26px #959595"
  },
  "gridTag": {
    "marginTop": "-45px"
  },
  "details": {
    "alignItems": "center",
    "backgroundColor": "#FFF"
  },
  "tableWidth": {
    "width": "100%"
  },
  "spaceHeight": {
    "height": "170px"
  },
  "vl": {
    "display": "none"
  },
  "boxTag": {
    "marginTop": "-35px"
  },
  "tr2": {
    "display": "none"
  },
  [theme.breakpoints.up("md")]: {
    "td1": {
      "width": "200px"
    },
    "td2": {
      "width": "auto"
    },
    "td3": {
      "width": "24px"
    },
    "ptitle": {
      "fontSize": "16px"
    },
    "pHeading": {
      "fontSize": "18px"
    },
    "primaryHeading": {
      "fontSize": "16px"
    },
    "accordionTag": {
      "marginTop": "20px"
    },
    "gridTag": {
      "marginTop": "-20px"
    },
    "vl": {
      "display": "block",
      "textAlign": "left",
      "borderLeft": "1px solid #eaeaea",
      "height": "60px"
    },
    "boxTag": {
      "marginTop": "-12px"
    },
    "tr2": {
      "display": "block"
    },
    "pDetails": {
      "fontWeight": 700,
      "color": "#46143C"
    }
  }

});

const productsGrid = (theme: any = {}) => createStyles({
  root: {
    flexGrow: 1,
  },
  purchaseButton: {
    borderRadius: 0,
    paddingTop: theme.spacing(2.8),
    paddingBottom: theme.spacing(2.4),
    backgroundColor: "#FFF",
    fontSize: "15px",
    fontFamily: "Verdana,Geneva,sans-serif",
    fontWeight: 700,
    textTransform: "uppercase",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: ".9px",
    "&:hover": {
      backgroundColor: theme.palette.primary.contrastText
    },
    color: theme.palette.primary.main,
    flexGrow: 1
  },
  productItemInnerContainer: {
    backgroundColor: "#46143C",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    color: "white",
    cursor: "pointer",
    textDecoration: "none",
  },
  productListContainerRoot: {
    alignItems: "stretch",
    marginBottom: theme.spacing(0),
  },
  cardTitle: {
    margin: 14,
    fontSize: '18px',
    lineHeight: 1,
    marginBottom: 5,
    marginLeft: 10,
    color: "#FFF",
    fontFamily: "Verdana,Geneva,sans-serif",
    fontWeight: 700,
    fontStyle: 'normal',
    textAlign: 'left'
  },
  priceTag: {
    textAlign: 'right',
    marginRight: '10px',
    fontFamily: "Verdana,Geneva,sans-serif",
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: '18px',
  },
  cardPrice: {
    display: 'none',
  },
  cardDesc: {
    textAlign: 'left',
    marginBottom: 5,
    marginLeft: 10,
    marginRight: 10,
    fontFamily: "Verdana,Geneva,sans-serif",
    padding: 0,
    border: 0,
    fontSize: '14px',
    lineHeight: '16px',
  },
  dividerTag: {
    display: 'none',
  },
  tableTag: {
    width: '100%'
  },
  [theme.breakpoints.up("md")]: {
    purchaseButton: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      fontSize: "16px",
    },
    productItemInnerContainer: {
      minHeight: "100%",
      justifyContent: "space-around",
      alignItems: "stretch",
    },
    cardTitle: {
      textAlign: 'center',
      fontSize: '31px',
      lineHeight: '37px'
    },
    priceTag: {
      display: 'none'
    },
    cardPrice: {
      fontFamily: "Verdana,Geneva,sans-serif",
      fontSize: "1.7rem",
      textAlign: 'center',
      height: "40px",
      display: 'block',
    },
    cardDesc: {
      fontSize: '16px',
      lineHeight: '18px',
      margin: "16px 0 21px",
      paddingBottom: "24px",
      paddingLeft: '15px',
      paddingRight: '15px',
      fontWeight: 400,
      textAlign: 'center',
      height: "15px"
    },
    dividerTag: {
      borderTop: "2px solid #a93091",
      height: "20px",
      display: 'block',
      width: '95%',
      marginLeft: 'auto',
      marginRight: 'auto'
    },

  },
});

const receipt = (theme: any = {}) => createStyles({
  "root": {
    "flexGrow": 1,
    "marginTop": "50px"
  },
  "receiptContainerRoot": {
    "alignItems": "stretch",
    "marginBottom": theme.spacing(3)
  },
  "receiptItemRoot": {
    "flexGrow": 1
  },
  "boxRoot": {
    "height": "auto",
    "width": "99%"
  },
  "paper": {
    "padding": theme.spacing(2),
    "textAlign": "center",
    "color": "theme.palette.text.secondary",
    "backgroundColor": "#FFF"
  },
  "successHeading": {
    "color": "rgb(126, 126, 126)",
    "textAlign": "left",
    "fontFamily": "Verdana, Geneva, sans-serif",
    "fontWeight": 540,
    "fontStyle": "normal",
    "fontSize": "18px",
    "marginLeft": "10px"
  },
  "vocherHeading": {
    "color": "#46143C",
    "textAlign": "left",
    "fontFamily": "Verdana, Geneva, sans-serif",
    "fontWeight": 540,
    "fontStyle": "normal",
    "fontSize": "18px",
    "marginLeft": "10px"
  },
  "label2": {
    "color": "#fff",
    "fontFamily": "Verdana,Geneva,sans-serif",
    "fontWeight": 400,
    "opacity": .5,
    "width": "auto",
    "whiteSpace": "pre-wrap",
    "overflow": "hidden",
    "textOverflow": "ellipsis"
  },
  "successIcon": {
    "color": "#46143c",
    "verticalAlign": "middle",
    "fontSize": 45
  },
  "errorIcon": {
    "color": "#46143c",
    "verticalAlign": "middle",
    "fontSize": 45
  },
  "subHeading": {
    "fontSize": "1rem",
    "color": "rgb(126, 126, 126)",
    "fontFamily": "Verdana, Geneva, sans-serif",
    "fontWeight": 100,
    "fontStyle": "normal"
  },
  "subHeadingLeft": {
    "fontSize": "16px",
    "color": "rgb(126, 126, 126)",
    "fontFamily": "Verdana, Geneva, sans-serif",
    "fontWeight": 100,
    "fontStyle": "normal",
    "textAlign": "left"
  },
  "subHeadingRight": {
    "fontSize": "16px",
    "color": "rgb(126, 126, 126)",
    "fontFamily": "Verdana, Geneva, sans-serif",
    "fontWeight": 100,
    "fontStyle": "normal",
    "textAlign": "right"
  },
  "descHeading": {
    "color": "rgb(126, 126, 126)",
    "textAlign": "left",
    "fontFamily": "Verdana, Geneva, sans-serif",
    "fontWeight": 100,
    "fontStyle": "normal",
    "fontSize": "16px",
    "marginLeft": "15px",
    "marginRight": "20px",
    "marginTop": "25px"
  },
  [theme.breakpoints.up("md")]: {
    "successHeading": {
      "fontSize": "32px"
    },
    "vocherHeading": {
      "fontSize": "36px"
    },
    "successIcon": {
      "fontSize": 85
    },
    "errorIcon": {
      "fontSize": 85
    },
    "subHeadingLeft": {
      "fontSize": "18px"
    },
    "subHeadingRight": {
      "fontSize": "18px"
    },
    "descHeading": {
      "fontSize": "22px"
    }
  }
});

const languageSelector = (theme: any = {}) => createStyles({
  "languageSelectorIcon": {
    "height": theme.spacing(3),
    "width": theme.spacing(3),
    "backgroundRepeat": "no-repeat",
    "backgroundPosition": "left",
    "backgroundSize": "contain"
  }
});

const pageWithBackgroundImageStyle = (theme: any = {}) => createStyles({
  "boxTag": {
    "display": "flex"
  },
  "root": (props: any) => ({
    "position": "relative",
    "maxHeight": "253px",
    "minHeight": "253px",
    "backgroundPositionX": "center",
    "width": "100%",
    "background": `linear-gradient(rgba(0,0,0,.16),rgba(0,0,0,.16)), url(${props?.domain}/tenant/img/header.jpg) no-repeat 50%/cover`,
    "backgroundSize": "cover",
    "zIndex": 0
  }),
  "help": (props: any) => ({
    "position": "relative",
    "maxHeight": "243px",
    "minHeight": "243px",
    "backgroundPositionX": "center",
    "background": `linear-gradient(rgba(0,0,0,.16),rgba(0,0,0,.16)), url(${props?.domain}/tenant/img/header.jpg) no-repeat 50%/cover`,
    "width": "100%",
    "backgroundSize": "cover",
    "zIndex": 0
  }),
  "checkout": (props: any) => ({
    "position": "relative",
    "maxHeight": "67px",
    "minHeight": '67px',
    "width": "100%",
    "background": `linear-gradient(rgba(0,0,0,.16),rgba(0,0,0,.16)), url(${props?.domain}/tenant/img/header.jpg) no-repeat 50%/cover`,
    "backgroundSize": "cover",
    "backgroundRepeat": "no-repeat",
    "backgroundPosition": "center",
    "zIndex": 0,
  }),
  "success": (props: any) => ({
    "position": "relative",
    "width": "100%",
    "maxHeight": "326px",
    "minHeight": '326px',
    "background": `linear-gradient(rgba(0,0,0,.16),rgba(0,0,0,.16)), url(${props?.domain}/tenant/img/header.jpg) no-repeat 50%/cover`,
    "backgroundSize": "cover",
    "backgroundRepeat": "no-repeat",
    "backgroundPosition": "center",
    "zIndex": 0,
  }),
  [theme.breakpoints.up("md")]: {
    "root": (props: any) => ({
      "maxHeight": "626px",
      "minHeight": '626px',
      "background": `url(${props?.domain}/tenant/img/header.jpg) no-repeat 50%/cover`,
    }),
    "help": (props: any) => ({
      "maxHeight": "380px",
      "minHeight": '380px',
      "backgroundColor": "#F0ECEF",
      "background": `url(${props?.domain}/tenant/img/header.jpg) no-repeat 50%/cover`,
    }),
    "checkout": (props: any) => ({
      "maxHeight": "153px",
      "minHeight": '153px',
      "background": `url(${props?.domain}/tenant/img/header.jpg) no-repeat 50%/cover`,
    }),
    "success": (props: any) => ({
      "maxHeight": "626px",
      "minHeight": '626px',
      "background": `url(${props?.domain}/tenanttenant/img/header.jpg) no-repeat 50%/cover`,
    }),
  }
});

const mobPageWithBackgroundImgStyle = (theme: any = {}) => createStyles({

  "root": {
    "position": "relative",
    "width": "100%",
    "backgroundColor": "#F0ECEF",
    "color": "theme.palette.common.white",
    "background": "`url(/tenant/img/header.jpg)`",
    "backgroundSize": "cover",
    "backgroundRepeat": "no-repeat",
    "backgroundPosition": "center",
    "zIndex": 0
  }

});

//
export {
  header, headerLogoStyle, footer, home, help, checkout, checkoutEmail,
  dropDownElement, orderSummary, productsGrid, receipt, languageSelector,
  pageWithBackgroundImageStyle, mobPageWithBackgroundImgStyle,
  branding, airlineThemePalette, colors, themeBack
};
import React from "react";
//import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import styled from 'styled-components'
import { AppBar, Box, ButtonBase, Drawer, Grid, Toolbar, Typography } from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import CloseButton from "./closeButton";
import { MobilePageWithBackgroundImage } from "./mobilePageWithBackgroundImage";
import { HeaderLogo } from "./headerLogo";
import { LanguageSelector } from "./languageSelector";
import { makeStyles } from '@material-ui/core/styles';
import { header, colors } from './../tenant/style';


export interface DefaultTheme {
  palette?: any,
  headerBackgroundColor?: string
}


function HeaderMenuItems(props: any) {
  const { screenType, content } = props;
    
  return (
    <>
      <Grid item>
        <LanguageSelector content={content} screenType={screenType} color={colors['languageSelectorColor']} />
      </Grid>
    </>
  );
}

function DesktopSection(props: any) {
  const {  content } = props
  return (
    <Grid container spacing={2} justify="flex-end" alignItems="center">
      <HeaderMenuItems  content={content} screenType={"desktop"} />
    </Grid>
  );
}

function MobileSection(props: any) {
  const useStyles = makeStyles(header);
  const classes = useStyles();
  const { colorBasedonBackgroundColor, content } = props
  const [open, setOpen] = React.useState(false);
  const theme: any = useTheme();

  const toggleDrawer = () => {
    setOpen(!open);
  };
  return (
    <Grid className={classes.mobileTag} container justify="flex-end"  alignItems="center">
      <ButtonBase onClick={toggleDrawer}>
        <Grid container direction="column"  justify="center" alignItems="stretch">
          <Grid item>
            {!open ? (
              <Menu className={classes.menuColor} stroke={theme.palette.primary.contrastText} />
            ) : (
              <CloseButton width="30px" height="30px" viewBox="0 0 44 44" stroke={theme.palette.primary.contrastText} />
            )}
          </Grid>
          <Grid  item>
            {!open ? (
              <Typography variant="body2" className={classes.menuContentColor} align="center">
                { content?.HEADER_MENU}
              </Typography>
            ) : (
              <Typography variant="body2" className={classes.menuContentColor} align="center">
                { content?.HEADER_CLOSE}
              </Typography>
            )}
          </Grid>
        </Grid>
      </ButtonBase>
      <Box  zIndex="-1" clone>
        <Drawer anchor="top" open={open} variant="persistent">
           <MobilePageWithBackgroundImage height="100vh"> 
            <Box width="100vw" height="100vh">
              <Box height="100px" />
              <Grid container spacing={4} justify="flex-start" alignItems="center" direction="column">
                <HeaderMenuItems content={content} screenType={ "mobile" } languageSelectorColor={colorBasedonBackgroundColor} setDrawerOpen={setOpen} />
              </Grid>
            </Box>
           </MobilePageWithBackgroundImage> 
        </Drawer>
      </Box>
    </Grid>
  );
}

export const Header = (props: any) => {
  const theme: DefaultTheme = useTheme();
  const useStyles = makeStyles(header);
  const classes = useStyles();
  const { content, domain } = props;

  let languageSelectorColor = "secondary";
  if (theme && theme.palette) {
    
    if (colors['contrastTextColor'] === theme.palette.secondary.contrastText) {
      languageSelectorColor = "primary";
    }
  } 

  return (
    <Box  py={2} clone>
      <AppBar position="relative" data-cy="header" color="primary" elevation={0}>
        <Toolbar >
          <Grid container alignItems="center">
            <Grid className={classes.LogoHeader} item xs={8} sm={5}>
              <HeaderLogo to="/" content={content} domain={domain} />
            </Grid>
            <Grid item xs={4} sm={7}>
                <div className={classes.smDownTag}>
                   <MobileSection content={content} colorBasedonBackgroundColor={colors['mobilrBasedonBackgroundColor']} />
                </div>
                <div className={classes.mdUpTag}>
                    <DesktopSection content={content} />
                </div>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
}


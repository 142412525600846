import React from "react";
import { useDispatch, useSelector } from "react-redux";
import DropDown from "./dropDown";
import { withStyles } from "@material-ui/core/styles";
import { appActions } from '../actions';
import { languageSelector } from './../tenant/style';
// backgroundImage: "url(svg/languageSelectorIcon.svg)",


export const LanguageSelector = withStyles(
  (languageSelector),
  { name: "LanguageSelector" }
)((props:any) => {

  const { classes, backgroundColor, color, screenType, content  } = props;
  const locale = useSelector((state:any) => state?.appData?.locale)
  const locales = useSelector((state:any) => state?.appData?.locales)
  const features = useSelector((state:any) => state?.appData?.features)
  const selectedLocale = (locales && locales.length)? (locales.find((item:any)=> item.id == locale) || {}) : ({id:locale, title:'En'});
  const dispatch = useDispatch();

  return (
    <DropDown
      backgroundColor={backgroundColor}
      color={color}
      items={locales}
      selectedItem={selectedLocale}
      icon={features?.UI?.showLangugageSelectorIcon ? <div className={classes.languageSelectorIcon} /> : null}
      showSelectedText={features?.UI?.showLanguageSelectorText}
      onChange={(item:any) => {
        dispatch(appActions.changeSelectedLocale(item.id));
      }}
      screenType={screenType}
      content={content}
    />
  );
});


